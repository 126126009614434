import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Helmet } from "react-helmet";
import $ from "jquery";

import {
  Row,
  Col,
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Footer, Testimonial, Vendors } from "./Home";

export class ProductsPage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet>
        <div
          class="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px;" }}
        >
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">Products</h1>
              <a href="" class="h5 text-white">
                Home
              </a>
              <i class="far fa-circle text-white px-2"></i>
              <a href="" class="h5 text-white">
                Products
              </a>
            </div>
          </div>
        </div>
        <Products></Products>
        <Testimonial></Testimonial>
        <Vendors></Vendors>
        <Footer></Footer>
      </>
    );
  }
}

export class Products extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet>

        {/* <!-- Products Start --> */}
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div
              class="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Our Products</h5>
              <h1 class="mb-0">Our Products for Your Successful Business</h1>
            </div>
            <div class="row g-5">
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-link text-white"></i>
                  </div>
                  <h4 class="mb-3">
                    eCommerce(B2C, B2B) -{" "}
                    <a href="https://ElasticCart.Com" target="_blank">
                      ElasticCART
                    </a>{" "}
                  </h4>
                  <p class="m-0">
                    <a href="https://ElasticCart.Com" target="_blank">
                      ElasticCART
                    </a>{" "}
                    is a Cloud ERP and eCommerce Platform to build Online
                    Stores.{" "}
                    <p>
                      We helped hundreds of businesses onboard online on the
                      same day. Get in touch with us and take action to increase
                      revenue and brand awareness. 
                    </p>
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-chart-pie text-white"></i>
                  </div>
                  <h4 class="mb-3">SMART POS, KIOSK POS, QRCode Ordering</h4>
                  <p class="m-0">
                    Our SmartPos is cloud-based and can run on any operating
                    system and device. It works great with any barcode reader
                    and weighing machine. It also supports product shortcodes
                    and fuzzy logic, and AI voice recognition is used for
                    product search and quick billing.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-link text-white"></i>
                  </div>
                  <h4 class="mb-3">ERP / Supply Chain Management System</h4>
                  <p class="m-0">
                    Our product supports end-to-end Supply Chain management
                    workflows such as Raw Material Management, Procurement
                    Management, Manufacturing, Servicing, Logistics, and Selling
                    (B2B,B2C). Our solution is cloud-based and
                    mobile-responsive.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-chart-pie text-white"></i>
                  </div>
                  <h4 class="mb-3">Retail and Wholesale Management System</h4>
                  <p class="m-0">
                    Our cloud-based system comes with POS, eCommerce (B2B, B2C),
                    Kiosk self-ordering, QRCode self-ordering, purchase
                    management, order management, packaging and delivery
                    management, 3rd party delivery, return management, and CRM.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-bed text-white"></i>
                  </div>
                  <h4 class="mb-3">Hotel and Restaurant Management System</h4>
                  <p class="m-0">
                    Our cloud-based system comes with Frontdesk Management,
                    Booking System (offline and eCommerce), Room Services, POS,
                    eCommerce, Table Orders, Kiosk Self Ordering, QRCode Self
                    Ordering, Kichen Management System, Purchase Managment,
                    Orders Management, Returns Management, and CRM
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-calendar text-white"></i>
                  </div>
                  <h4 class="mb-3">CRM & Booking System</h4>
                  <p class="m-0">
                    Our cloud-based CRM is simple and powerful. It aids in
                    customer registration, customer visits, and customer
                    retention. History, Appointment Booking and Scheduling,
                    Email Marketing and Notification, Coupon Management, and
                    Loyalty Points Management
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-life-ring text-white"></i>
                  </div>
                  <h4 class="mb-3">Storage and Content Management System</h4>
                  <p class="m-0">
                    Our storage and content management system is a simple and
                    powerful cloud-based solution. It can securely store and
                    share your data and access it from anywhere in the world
                    with the right permission.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-search text-white"></i>
                  </div>
                  <h4 class="mb-3">Employee & Vehicle(IoT) Tracking System</h4>
                  <p class="m-0">
                    The employee tracking system is powerful and provides live
                    location and total travel distance of employees, which help
                    businesses track executives during working hours and
                    increase business productivity. Our IoT-based
                    vehicle/resource tracking system can track the real-time
                    location of any resource on the planet.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div class="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                  <h3 class="text-white mb-3">Call Us For Quote</h3>
                  <p class="text-white mb-3">
                    Please get in touch with our sales team to understand your
                    requirements and to provide a free quotation!
                  </p>
                  <h3 class="text-white mb-0">+91 98805 82728</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
