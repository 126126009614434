import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Helmet } from "react-helmet";
import $ from "jquery";

import {
  Row,
  Col,
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Footer, Testimonial, Vendors } from "./Home";

export class QuotePage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div
          class="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px;" }}
        >
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">Free Quote</h1>
              <a href="" class="h5 text-white">
                Home
              </a>
              <i class="far fa-circle text-white px-2"></i>
              <a href="" class="h5 text-white">
                Free Quote
              </a>
            </div>
          </div>
        </div>
        <Quote></Quote>
        <Testimonial></Testimonial>
        <Vendors></Vendors>
        <Footer></Footer>
      </>
    );
  }
}

export class Quote extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div class="row g-5">
              <div class="col-lg-7">
                <div class="section-title position-relative pb-3 mb-5">
                  <h5 class="fw-bold text-primary text-uppercase">
                    Request A Quote
                  </h5>
                  <h1 class="mb-0">
                    Need A Free Quote? Please Feel Free to Contact Us
                  </h1>
                </div>
                <div class="row gx-3">
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 class="mb-4">
                      <i class="fa fa-reply text-primary me-3"></i>Reply within
                      24 hours
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-4">
                      <i class="fa fa-phone-alt text-primary me-3"></i>24 hrs
                      telephone support
                    </h5>
                  </div>
                </div>
                <p class="mb-4">
                  Thanks for showing interest in doing business with us. We
                  would be happy to help you!
                </p>
                <div
                  class="d-flex align-items-center mt-2 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    class="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px;", height: "60px;" }}
                  >
                    <i class="fa fa-phone-alt text-white"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="mb-2">Call to ask any question</h5>
                    <h4 class="text-primary mb-0">+91 98805 82728</h4>
                  </div>
                </div>
              </div>
              <div class="col-lg-5">
                <div
                  class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  <form>
                    <div class="row g-3">
                      <div class="col-xl-12">
                        <input
                          type="text"
                          class="form-control bg-light border-0"
                          placeholder="Your Name"
                          style={{ height: "55px;" }}
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="email"
                          class="form-control bg-light border-0"
                          placeholder="Your Email"
                          style={{ height: "55px;" }}
                        />
                      </div>
                      <div class="col-12">
                        <select
                          class="form-select bg-light border-0"
                          style={{ height: "55px;" }}
                        >
                          <option selected>Select A Service</option>
                          <option value="1">Service 1</option>
                          <option value="2">Service 2</option>
                          <option value="3">Service 3</option>
                        </select>
                      </div>
                      <div class="col-12">
                        <textarea
                          class="form-control bg-light border-0"
                          rows="3"
                          placeholder="Message"
                        ></textarea>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-dark w-100 py-3" type="submit">
                          Request A Quote
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
