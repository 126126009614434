import React, { createContext, useState, useEffect } from "react";
import Fuse from "fuse.js";
import axios from "axios";

const CartContext = createContext();
export default CartContext;

export function CartProvider({ children }) {
  // const [commonSettings, setCommonSettings] = useState([]);

  // const GetStore = () => {
  //   fetch(process.env.REACT_APP_API + "Sales/GetStore/" + storeId)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status == 400) {
  //       } else {
  //         document.title = data.name;
  //         setStore(data);
  //       }
  //     })
  //     .catch((error) => {
  //       alert("GetStores Error:: " + error);
  //     });
  // };

  //when page is reloaded, restore the cart from cookie
  //todo: clear the cookie when localstorage is older than a week/month
  useEffect(() => {}, []);

  const ClearAllCookies = () => {
    //1. clear Cart Items
    localStorage.setItem("localstoragecartitems", null);
  };

  return <CartContext.Provider value={{}}>{children}</CartContext.Provider>;
}
