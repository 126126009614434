import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Products } from "./Products";
import { Helmet } from "react-helmet";
import $ from "jquery";

import {
  Row,
  Col,
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Services } from "./Services";
import { Aboutus } from "./Aboutus";

export class Home extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet>
        {/* <!-- Navbar & Carousel Start --> */}
        <div class="container-fluid position-relative p-0">
          <div
            id="header-carousel"
            class="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="w-100" src="img/carousel-1.png" alt="Image" />
                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style={{ maxWidth: "900px;" }}>
                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                      Creative & Innovative
                    </h5>
                    <h1 class="display-3 text-white mb-md-4 animated zoomIn">
                      Best Digital Solutions for your Business
                    </h1>
                    <a
                      href="/QuotePage"
                      class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Free Quote
                    </a>
                    <a
                      href="/ContactusPage"
                      class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img class="w-100" src="img/carousel-3.jpg" alt="Image" />
                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style={{ maxWidth: "900px;" }}>
                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                      Creative & Innovative
                    </h5>
                    <h1 class="display-3 text-white mb-md-4 animated zoomIn">
                      Modernize & Transform your Business
                    </h1>
                    <a
                      href="quote.html"
                      class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Free Quote
                    </a>
                    <a
                      href=""
                      class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div class="carousel-item ">
                <img class="w-100" src="img/carousel-1.png" alt="Image" />
                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style={{ maxWidth: "900px;" }}>
                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                      ERP & E-COMMERCE
                    </h5>
                    <h1 class="display-3 text-white mb-md-4 animated zoomIn">
                      End-to-End Business Solution with our ERP product Suite
                    </h1>
                    <a
                      href="/QuotePage"
                      class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Free Quote
                    </a>
                    <a
                      href="/ContactusPage"
                      class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img class="w-100" src="img/carousel-3.jpg" alt="Image" />
                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style={{ maxWidth: "900px;" }}>
                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">
                      Business Automation
                    </h5>
                    <h1 class="display-3 text-white mb-md-4 animated zoomIn">
                      Automate your Business Process with cutting-edge
                      Technology
                    </h1>
                    <a
                      href="quote.html"
                      class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Free Quote
                    </a>
                    <a
                      href=""
                      class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#header-carousel"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        {/* <!-- Full Screen Search Start --> */}
        {/* <div class="modal fade" id="searchModal" tabindex="-1">
          <div class="modal-dialog modal-fullscreen">
            <div
              class="modal-content"
              style={{ background: "rgba(9, 30, 62, .7);" }}
            >
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body d-flex align-items-center justify-content-center">
                <div class="input-group" style={{ maxWidth: "600px;" }}>
                  <input
                    type="text"
                    class="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button class="btn btn-primary px-4">
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- Facts Start --> */}
        <div class="container-fluid facts py-5 pt-lg-0">
          <div class="container py-5 pt-lg-0">
            <div class="row gx-0">
              <div class="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                <div
                  class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px;" }}
                >
                  <div
                    class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px", height: "60px;" }}
                  >
                    <i class="fa fa-users text-primary"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="text-white mb-0">Happy Clients</h5>
                    <Row>
                      <Col>
                        <h1 class="text-white mb-0" data-toggle="counter-up">
                          350
                        </h1>
                      </Col>
                      <Col>
                        <h1 class="text-white mb-0">+</h1>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                <div
                  class="bg-light shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px;" }}
                >
                  <div
                    class="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px;", height: "60px;" }}
                  >
                    <i class="fa fa-check text-white"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="text-primary mb-0">Projects Done</h5>
                    <Row>
                      <Col>
                        <h1 class="mb-0" data-toggle="counter-up">
                          375
                        </h1>
                      </Col>
                      <Col>
                        <h1 class=" mb-0">+</h1>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                <div
                  class="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px;" }}
                >
                  <div
                    class="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px;", height: "60px;" }}
                  >
                    <i class="fa fa-award text-primary"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="text-white mb-0">Experience</h5>
                    <Row>
                      <Col>
                        <h1 class="text-white mb-0" data-toggle="counter-up">
                          25
                        </h1>
                      </Col>
                      <Col>
                        <h1 class="text-white mb-0">+</h1>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Services></Services>
        <Products></Products>
        <Aboutus></Aboutus>
        <Testimonial></Testimonial>

        {/* <!-- Team Start --> */}
        {/* <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div
              class="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Team Members</h5>
              <h1 class="mb-0">
                Professional Stuffs Ready to Help Your Business
              </h1>
            </div>
            <div class="row g-5">
              <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div class="team-item bg-light rounded overflow-hidden">
                  <div class="team-img position-relative overflow-hidden">
                    <img class="img-fluid w-100" src="img/team-1.jpg" alt="" />
                    <div class="team-social">
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-twitter fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-facebook-f fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-instagram fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-linkedin-in fw-normal"></i>
                      </a>
                    </div>
                  </div>
                  <div class="text-center py-4">
                    <h4 class="text-primary">Full Name</h4>
                    <p class="text-uppercase m-0">Designation</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div class="team-item bg-light rounded overflow-hidden">
                  <div class="team-img position-relative overflow-hidden">
                    <img class="img-fluid w-100" src="img/team-2.jpg" alt="" />
                    <div class="team-social">
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-twitter fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-facebook-f fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-instagram fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-linkedin-in fw-normal"></i>
                      </a>
                    </div>
                  </div>
                  <div class="text-center py-4">
                    <h4 class="text-primary">Full Name</h4>
                    <p class="text-uppercase m-0">Designation</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div class="team-item bg-light rounded overflow-hidden">
                  <div class="team-img position-relative overflow-hidden">
                    <img class="img-fluid w-100" src="img/team-3.jpg" alt="" />
                    <div class="team-social">
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-twitter fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-facebook-f fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-instagram fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-lg btn-primary btn-lg-square rounded"
                        href=""
                      >
                        <i class="fab fa-linkedin-in fw-normal"></i>
                      </a>
                    </div>
                  </div>
                  <div class="text-center py-4">
                    <h4 class="text-primary">Full Name</h4>
                    <p class="text-uppercase m-0">Designation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- Blog Start --> */}
        {/* <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div
              class="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Latest Blog</h5>
              <h1 class="mb-0">Read The Latest Articles from Our Blog Post</h1>
            </div>
            <div class="row g-5">
              <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div class="blog-item bg-light rounded overflow-hidden">
                  <div class="blog-img position-relative overflow-hidden">
                    <img class="img-fluid" src="img/blog-1.jpg" alt="" />
                    <a
                      class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                      href=""
                    >
                      Web Design
                    </a>
                  </div>
                  <div class="p-4">
                    <div class="d-flex mb-3">
                      <small class="me-3">
                        <i class="far fa-user text-primary me-2"></i>John Doe
                      </small>
                      <small>
                        <i class="far fa-calendar-alt text-primary me-2"></i>01
                        Jan, 2045
                      </small>
                    </div>
                    <h4 class="mb-3">How to build a website</h4>
                    <p>
                      Dolor et eos labore stet justo sed est sed sed sed dolor
                      stet amet
                    </p>
                    <a class="text-uppercase" href="">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div class="blog-item bg-light rounded overflow-hidden">
                  <div class="blog-img position-relative overflow-hidden">
                    <img class="img-fluid" src="img/blog-2.jpg" alt="" />
                    <a
                      class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                      href=""
                    >
                      Web Design
                    </a>
                  </div>
                  <div class="p-4">
                    <div class="d-flex mb-3">
                      <small class="me-3">
                        <i class="far fa-user text-primary me-2"></i>John Doe
                      </small>
                      <small>
                        <i class="far fa-calendar-alt text-primary me-2"></i>01
                        Jan, 2045
                      </small>
                    </div>
                    <h4 class="mb-3">How to build a website</h4>
                    <p>
                      Dolor et eos labore stet justo sed est sed sed sed dolor
                      stet amet
                    </p>
                    <a class="text-uppercase" href="">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div class="blog-item bg-light rounded overflow-hidden">
                  <div class="blog-img position-relative overflow-hidden">
                    <img class="img-fluid" src="img/blog-3.jpg" alt="" />
                    <a
                      class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                      href=""
                    >
                      Web Design
                    </a>
                  </div>
                  <div class="p-4">
                    <div class="d-flex mb-3">
                      <small class="me-3">
                        <i class="far fa-user text-primary me-2"></i>John Doe
                      </small>
                      <small>
                        <i class="far fa-calendar-alt text-primary me-2"></i>01
                        Jan, 2045
                      </small>
                    </div>
                    <h4 class="mb-3">How to build a website</h4>
                    <p>
                      Dolor et eos labore stet justo sed est sed sed sed dolor
                      stet amet
                    </p>
                    <a class="text-uppercase" href="">
                      Read More <i class="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Vendors></Vendors>
        <Footer></Footer>
      </>
    );
  }
}

export class Testimonial extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* <!-- Testimonial Start --> */}
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div
              class="section-title text-center position-relative pb-3 mb-4 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Testimonial</h5>
              <h1 class="mb-0">
                What Our Clients Say About Our Digital Services
              </h1>
            </div>
            <div
              class="owl-carousel testimonial-carousel wow fadeInUp"
              data-wow-delay="0.6s"
            >
              <div class="testimonial-item bg-light my-4">
                <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                  {/* <img
                    class="img-fluid rounded"
                    src="img/testimonial-1.jpg"
                    style={{ width: "60px;", height: "60px;" }}
                  /> */}
                  <div class="ps-4">
                    <h4 class="text-primary mb-1">Mr.Rajan</h4>
                    <small class="text-uppercase">
                      Director of AllSaints Grocery & Bakery
                    </small>
                  </div>
                </div>
                <div class="pt-4 pb-5 px-5">
                  SANTREAZA team helped my business sell online under my own
                  brand. My online store got up and running on the same day! I
                  appreciate all your great support. Looking forward to working
                  on other Projects.
                </div>
              </div>
              <div class="testimonial-item bg-light my-4">
                <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                  {/* <img
                    class="img-fluid rounded"
                    src="img/testimonial-2.jpg"
                    style={{ width: "60px;", height: "60px;" }}
                  /> */}
                  <div class="ps-4">
                    <h4 class="text-primary mb-1">Mr.Ramachandran</h4>
                    <small class="text-uppercase">
                      Director of Pineappleexpress
                    </small>
                  </div>
                </div>
                <div class="pt-4 pb-5 px-5">
                  Now I'm selling products online (B2B and B2C) under my own
                  brand. This is a great feel. My online store looks stunning
                  and great. performance seeing with 5000+ products on my store.
                  Thanks to SANTREAZA Team for great solution and support! I am
                  trying to replace my older POS system with SANTREZA's Smart
                  POS now:). I'd recommend it to my friends.
                </div>
              </div>

              <div class="testimonial-item bg-light my-4">
                <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                  {/* <img
                    class="img-fluid rounded"
                    src="img/testimonial-4.jpg"
                    style={{ width: "60px;", height: "60px;" }}
                  /> */}
                  <div class="ps-4">
                    <h4 class="text-primary mb-1">Mr. Manju</h4>
                    <small class="text-uppercase">Director of SpicyHut</small>
                  </div>
                </div>
                <div class="pt-4 pb-5 px-5">
                  We use the total solution of Restaurant Management Solution
                  from SANTREAZA. It helps our business go to the next level
                  with powerful POS, eCommerce and KIOSK-based solutions. Our
                  Management productivity improved a lot and we see a lot of
                  savings.
                </div>
              </div>
              <div class="testimonial-item bg-light my-4">
                <div class="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                  {/* <img
                    class="img-fluid rounded"
                    src="img/testimonial-4.jpg"
                    style={{ width: "60px;", height: "60px;" }}
                  /> */}
                  <div class="ps-4">
                    <h4 class="text-primary mb-1">Mr. Prasanna</h4>
                    <small class="text-uppercase">
                      Director of MGR (Meenakshi Gateway Restaurant)
                    </small>
                  </div>
                </div>
                <div class="pt-4 pb-5 px-5">
                  We are using POS and eCommerce products from SANTREAZA. We are
                  happy with the product. Great Support and Kudos to the
                  SANTREAZA Support Team for stunning Online Store.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class Vendors extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* <!-- Vendor Start --> */}
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5 mb-5">
            <div
              class="section-title text-center position-relative pb-3 mb-4 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Our Clients</h5>
            </div>
            <div class="bg-white">
              <div class="owl-carousel vendor-carousel">
                <img
                  src="img/green.png"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
                <img
                  src="img/allsaints.png"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
                <img
                  src="img/godwill.png"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
                <img
                  src="img/mediuna.png"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
                <img
                  src="img/mgr.png"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
                <img
                  src="img/myfairlady.png"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
                <img
                  src="img/pineappleexpress.png"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
                <img
                  src="img/cura.PNG"
                  alt=""
                  style={{
                    width: "3.5cm",
                    height: "2cm",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export class Footer extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* <!-- Footer Start --> */}
        <div
          class="container-fluid bg-dark text-light mt-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="container">
            <div class="row gx-5">
              <div class="col-lg-4 col-md-6 footer-about">
                <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                  <a href="index.html" class="navbar-brand">
                    <h1 class="m-0 text-white">
                      {/* <i class="fa fa-user-tie me-2"></i> */}
                      SANTREZA
                    </h1>
                  </a>
                  <p class="mt-3 mb-4">
                    SANTREZA helps Client run their mission-critical systems and
                    operations while modernizing IT, optimizing data
                    architectures, and ensuring security and scalability across
                    public, private and hybrid clouds. The Learn more about how
                    we deliver excellence for our customers and colleagues at
                    SANTREZA.com.
                  </p>
                  <form action="">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control border-white p-3"
                        placeholder="Your Email"
                      />
                      <button class="btn btn-dark">Sign Up</button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-8 col-md-6">
                <div class="row gx-5">
                  <div class="col-lg-4 col-md-12 pt-5 mb-5">
                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 class="text-light mb-0">Get In Touch</h3>
                    </div>
                    <div class="d-flex mb-2">
                      <i class="bi bi-geo-alt text-primary me-2"></i>
                      <p class="mb-0">
                        230 Kammasandra Road, Electronic City, Bangalore,
                        Karnataka, India 560100.
                      </p>
                    </div>
                    <div class="d-flex mb-2">
                      <i class="bi bi-envelope-open text-primary me-2"></i>
                      <p class="mb-0">support@elasticcart.com</p>
                    </div>
                    <div class="d-flex mb-2">
                      <i class="bi bi-telephone text-primary me-2"></i>
                      <p class="mb-0">+91 98805 82728</p>
                    </div>
                    <div class="d-flex mt-4">
                      <a class="btn btn-primary btn-square me-2" href="#">
                        <i class="fab fa-twitter fw-normal"></i>
                      </a>
                      <a class="btn btn-primary btn-square me-2" href="#">
                        <i class="fab fa-facebook-f fw-normal"></i>
                      </a>
                      <a
                        class="btn btn-primary btn-square me-2"
                        href="https://www.linkedin.com/in/santreza-it-solutions-78917a277/"
                      >
                        <i class="fab fa-linkedin-in fw-normal"></i>
                      </a>
                      <a class="btn btn-primary btn-square" href="#">
                        <i class="fab fa-instagram fw-normal"></i>
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 class="text-light mb-0">Quick Links</h3>
                    </div>
                    <div class="link-animated d-flex flex-column justify-content-start">
                      <a class="text-light mb-2" href="/Home">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Home
                      </a>
                      <a class="text-light mb-2" href="/ProductsPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Our
                        Products
                      </a>
                      <a class="text-light mb-2" href="/ServicesPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Our
                        Services
                      </a>
                      <a class="text-light mb-2" href="/OutsourcingPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Outsourcing
                      </a>
                      <a class="text-light mb-2" href="/CareersPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        CareersPage
                      </a>
                      <a class="text-light mb-2" href="/AboutusPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>About
                        Us
                      </a>
                      {/* <a class="text-light mb-2" href="#">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Meet
                        The Team
                      </a>
                      <a class="text-light mb-2" href="#">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Latest Blog
                      </a> */}
                      <a class="text-light" href="/ContactusPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Contact Us
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                    <div class="section-title section-title-sm position-relative pb-3 mb-4">
                      <h3 class="text-light mb-0">Popular Links</h3>
                    </div>
                    <div class="link-animated d-flex flex-column justify-content-start">
                      <a class="text-light mb-2" href="/Home">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Home
                      </a>
                      <a class="text-light mb-2" href="/ProductsPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Our
                        Products
                      </a>
                      <a class="text-light mb-2" href="/ServicesPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Our
                        Services
                      </a>
                      <a class="text-light mb-2" href="/OutsourcingPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Outsourcing
                      </a>
                      <a class="text-light mb-2" href="/CareersPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        CareersPage
                      </a>
                      <a class="text-light mb-2" href="/AboutusPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>About
                        Us
                      </a>

                      {/* <a class="text-light mb-2" href="#">
                        <i class="bi bi-arrow-right text-primary me-2"></i>Meet
                        The Team
                      </a>
                      <a class="text-light mb-2" href="#">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Latest Blog
                      </a> */}
                      <a class="text-light" href="/ContactusPage">
                        <i class="bi bi-arrow-right text-primary me-2"></i>
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- License Start --> */}
        <div
          class="container-fluid text-white"
          style={{ background: "#061429;" }}
        >
          <div class="container text-center">
            <div class="row justify-content-end">
              <div class="col-lg-8 col-md-6">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style={{ height: "75px;" }}
                >
                  <p class="mb-0">
                    &copy;{" "}
                    <a class="text-white border-bottom" href="#">
                      SANTREZA
                    </a>
                    {/* . All Rights Reserved. This template is free as long as you
                    keep the footer author’s credit link/attribution
                    link/backlink. If you'd like to use the template without the
                    footer author’s credit link/attribution link/backlink, you
                    can purchase the Credit Removal License from
                    "https://htmlcodex.com/credit-removal". Thank you for your
                    support. Designed by{" "} */}
                    <a
                      class="text-white border-bottom"
                      href="https://htmlcodex.com"
                    >
                      HTML Codex
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Back to Top --> */}
        <a
          href="#"
          class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i class="bi bi-arrow-up"></i>
        </a>
      </>
    );
  }
}
