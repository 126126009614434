import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Helmet } from "react-helmet";
import $ from "jquery";

import {
  Row,
  Col,
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Footer, Testimonial, Vendors } from "./Home";

export class OutsourcingPage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet> */}
        <div
          class="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px;" }}
        >
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">Outsourcing </h1>
              <a href="" class="h5 text-white">
                Home
              </a>
              <i class="far fa-circle text-white px-2"></i>
              <a href="" class="h5 text-white">
                About
              </a>
            </div>
          </div>
        </div>
        <Careers></Careers>
        {/* <Testimonial></Testimonial>
        <Vendors></Vendors> */}
        <Footer></Footer>
      </>
    );
  }
}

export class Careers extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet>

        {/* <!-- About Start --> */}
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div class="row g-5">
              <div class="col-lg-7">
                <div class="section-title position-relative pb-3 mb-5">
                  <h5 class="fw-bold text-primary text-uppercase">Careers</h5>
                  <h1 class="mb-0">
                    We are Best-in-class and cutting-edge IT Solution Company.
                    Contact us for any C2C(Corp-to-Corp), Contract or full-time
                    employment requirements !
                  </h1>
                </div>
                <p class="mb-4">
                  We help our Clients and Partners with highly skilled Software
                  Engineers & Architects to acomplish their mission with quality
                  of work in given time frame.
                </p>
                <div class="row g-0 mb-3">
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Front-end
                      Developers [Web / Mobile]
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Full-Stack
                      Developers
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>DevOps
                      Engineers
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Cloud
                      Engineers
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Software
                      Solution Architects
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Cloud
                      Engineers
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i> AI, ML, DL
                      and GenAI Specilists
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i> IoT Solution
                      Engineers
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i> Data &
                      Analytics Engineers
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i> Big Data
                      Experts
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Cloud
                      Solution Architects
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Enterprise
                      Architects
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>
                      Software Test Engineers
                    </h5>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center mb-4 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    class="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px;", height: "60px;" }}
                  >
                    <i class="fa fa-phone-alt text-white"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="mb-2">Call / Email US </h5>
                    <h4 class="text-primary mb-0">
                      +91 98805 82728, +91 96636 17620
                    </h4>
                    <h4 class="text-primary mb-0">support@elasticcart.com</h4>
                  </div>
                </div>
              </div>
              <div class="col-lg-5" style={{ minHeight: "500px;" }}>
                <div class="position-relative h-100">
                  <img
                    class="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="img/about.jpg"
                    style={{ objectFit: "cover;" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
