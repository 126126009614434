import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Helmet } from "react-helmet";
import $ from "jquery";

import {
  Row,
  Col,
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Footer, Testimonial, Vendors } from "./Home";

export class ServicesPage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet> */}
        <div
          class="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px;" }}
        >
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">
                Solution & Services
              </h1>
              <a href="" class="h5 text-white">
                Home
              </a>
              <i class="far fa-circle text-white px-2"></i>
              <a href="" class="h5 text-white">
                Solution & Services
              </a>
            </div>
          </div>
        </div>
        <Services></Services>
        <Testimonial></Testimonial>
        <Vendors></Vendors>
        <Footer></Footer>
      </>
    );
  }
}

export class Services extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet>
        {/* <!-- Service Start --> */}
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div
              class="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Our Services</h5>
              <h1 class="mb-0">
                Custom IT Solutions for Your Successful Business
              </h1>
            </div>
            <div class="row g-5">
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-digital-tachograph text-white"></i>
                  </div>
                  <h4 class="mb-3">
                    Digital Transformation (Migrate & Modernize)
                  </h4>
                  <p class="m-0">
                    We help businesses modernize and transform with
                    modern cloud-based solutions and cutting-edge technology.
                    Our expertize has assisted over 100 clients. We are experts
                    in app migration from on-premises to the cloud, cloud
                    computing, microservices, and API developments
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-code text-white"></i>
                  </div>
                  <h4 class="mb-3">Web & Mobile Development</h4>
                  <p class="m-0">
                    We specialize in the creation of responsive web and mobile
                    applications. Our team consists of architects, business
                    analysts, developers, and testers. We create cloud-hosted
                    applications that are user-friendly, dependable, robust, and
                    error-free. We help our clients with quick prototypes. We
                    also provide custom solutions.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-chart-pie text-white"></i>
                  </div>
                  <h4 class="mb-3">Big Data, IoT & Analytics</h4>
                  <p class="m-0">
                    We offer Spark/Databricks based solutions to get insight
                    from data and to learn predictive knowledge about the
                    business, along with integrating machine learning solutions.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fab fa-searchengin text-white"></i>
                  </div>
                  <h4 class="mb-3">Managed DEVOPS & Platform Engineering</h4>
                  <p class="m-0">
                    With a wide range of expertise in the development, build,
                    package, and deployment of applications and data, we helped
                    many of our clients with a great DevOps solution. We
                    specialize in cloud and IaC technology solutions. We offer
                    managed services to take care of your business IT
                    infrastructure and devOps.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                <div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                  <div class="service-icon">
                    <i class="fa fa-search text-white"></i>
                  </div>
                  <h4 class="mb-3">AI, Machine Learning(ML) </h4>
                  <p class="m-0">
                    We delighted our customers with a wide range of experience
                    in AI and ML technology. We offered great solutions to our
                    customers, which helped their businesses be productive and
                    gave them great insight about their businesses.
                  </p>
                  <a class="btn btn-lg btn-primary rounded" href="">
                    <i class="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                <div class="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                  <h3 class="text-white mb-3">Call Us For Quote</h3>
                  <p class="text-white mb-3">
                    Please get in touch with our sales team to understand your
                    requirements and to provide a free quotation!
                  </p>
                  <h3 class="text-white mb-0">+91 98805 82728</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
