import React, { Component, useState } from "react";
import { useContext } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import axios from "axios";
import { Helmet } from "react-helmet";
import $ from "jquery";

import {
  Row,
  Col,
  Accordion,
  Table,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CartContext from "./CartContext";
import { Footer, Testimonial, Vendors } from "./Home";

export class AboutusPage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet> */}
        <div
          class="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px;" }}
        >
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">About </h1>
              <a href="" class="h5 text-white">
                Home
              </a>
              <i class="far fa-circle text-white px-2"></i>
              <a href="" class="h5 text-white">
                About
              </a>
            </div>
          </div>
        </div>
        <Aboutus></Aboutus>
        <Testimonial></Testimonial>
        <Vendors></Vendors>
        <Footer></Footer>
      </>
    );
  }
}

export class Aboutus extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet>

        {/* <!-- About Start --> */}
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div class="row g-5">
              <div class="col-lg-7">
                <div class="section-title position-relative pb-3 mb-5">
                  <h5 class="fw-bold text-primary text-uppercase">About Us</h5>
                  <h1 class="mb-0">
                    The Best IT Solution With 25+ Years of Experience
                  </h1>
                </div>
                <p class="mb-4">
                  We offer simple and more innovative solutions, and our clients
                  are overjoyed with our excellent work. Our expertise in
                  digital transformation and modern technology has helped many
                  customers resolve business issues, generate more revenues, and
                  reduce business running costs.
                </p>
                <div class="row g-0 mb-3">
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Creative &
                      Innovative Solution
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Professional
                      Staff
                    </h5>
                  </div>
                  <div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>24/7 Support
                    </h5>
                    <h5 class="mb-3">
                      <i class="fa fa-check text-primary me-3"></i>Fair Prices
                    </h5>
                  </div>
                </div>
                <div
                  class="d-flex align-items-center mb-4 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    class="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px;", height: "60px;" }}
                  >
                    <i class="fa fa-phone-alt text-white"></i>
                  </div>
                  <div class="ps-4">
                    <h5 class="mb-2">Call to ask any question</h5>
                    <h4 class="text-primary mb-0">+(91) 98805 82728</h4>
                  </div>
                </div>
                <a
                  href="/QuotePage"
                  class="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  Request A Quote
                </a>
              </div>
              <div class="col-lg-5" style={{ minHeight: "500px;" }}>
                <div class="position-relative h-100">
                  <img
                    class="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="img/about.jpg"
                    style={{ objectFit: "cover;" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Features Start --> */}
        <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div class="container py-5">
            <div
              class="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: "600px;" }}
            >
              <h5 class="fw-bold text-primary text-uppercase">Why Choose Us</h5>
              <h1 class="mb-0">
                We Are Here to Grow Your Business Exponentially
              </h1>
            </div>
            <div class="row g-5">
              <div class="col-lg-4">
                <div class="row g-5">
                  <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                    <div
                      class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px;", height: "60px;" }}
                    >
                      <i class="fa fa-cubes text-white"></i>
                    </div>
                    <h4>Best In Industry</h4>
                    <p class="mb-0">
                      {" "}
                      We are experts in delivering creative and innovative
                      solutions. Our expertise in modern technology has helped
                      many Customers in Digital Transformation. Out Products and
                      Many customers benefited from the solutions. Process and
                      solve mission Critical Challenges.
                    </p>
                  </div>
                  <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                    <div
                      class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px;", height: "60px;" }}
                    >
                      <i class="fa fa-award text-white"></i>
                    </div>
                    <h4>Domain Experts</h4>
                    <p class="mb-0">
                      We are specialized and have vast experience in Retail,
                      Manufacturing, Supply Chain, Industrial Automation,
                      eCommerce, intelligent POS, hotels and restaurants,
                      pharmaceuticals Banking and Insurance domains. We offer a
                      simple and powerful solutions all the time.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-4  wow zoomIn"
                data-wow-delay="0.9s"
                style={{ minHeight: "350px;" }}
              >
                <div class="position-relative h-100">
                  <img
                    class="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.1s"
                    src="img/feature.jpg"
                    style={{ objectFit: "cover;" }}
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="row g-5">
                  <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                    <div
                      class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px;", height: "60px;" }}
                    >
                      <i class="fa fa-users-cog text-white"></i>
                    </div>
                    <h4>Professional Staff</h4>
                    <p class="mb-0">
                      With 25+ years of experience in information technology, we
                      are constantly growing and staying current on Modern
                      Technology and Solutioning expertize. We work closely with
                      our Clients to ensure that we always provide the best
                      service possible.
                    </p>
                  </div>
                  <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                    <div
                      class="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px;", height: "60px;" }}
                    >
                      <i class="fa fa-phone-alt text-white"></i>
                    </div>
                    <h4>24/7 Support</h4>
                    <p class="mb-0">
                      We are available 24/7 to support our customer business
                      challenges.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
