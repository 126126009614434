import React, { Component, useContext } from "react";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BsFillHeartFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  Badge,
  OverlayTrigger,
  Popover,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import Select from "react-select";

import "../CSS/textanimation.css";
import { Navbar, Nav, Button, Form, FormControl } from "react-bootstrap";
import CartContext from "./CartContext";

export default class Navigation extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
  }

  componentDidMount() {}
  componentWillUnmount() {}
  unload(e) {}

  componentDidUpdate() {}

  render() {
    return (
      <>
        <Helmet>
          <script src="/lib/wow/wow.min.js"></script>
          <script src="/lib/easing/easing.min.js"></script>
          <script src="/lib/waypoints/waypoints.min.js"></script>
          <script src="/lib/counterup/counterup.min.js"></script>
          <script src="/lib/owlcarousel/owl.carousel.min.js"></script>
          <script src="/js/main.js"></script>
        </Helmet>
        <a
          class="whats-app "
          href={"https://wa.me/" + 9880582728}
          target="_blank"
        >
          <i class="bi bi-whatsapp my-float"></i>
        </a>
        <div
          id="spinner"
          class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
        >
          <div class="spinner"></div>
        </div>

        <div class="container-fluid bg-dark px-5 d-none d-lg-block">
          <div class="row gx-0">
            <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
              <div
                class="d-inline-flex align-items-center"
                style={{ height: "45px;" }}
              >
                <small class="me-3 text-light">
                  <i class="fa fa-map-marker-alt me-2"></i>230 Kammasandra Road,
                  Electronic City, Bangalore, India 560100
                </small>
                <small class="me-3 text-light">
                  <i class="fa fa-phone-alt me-2"></i>+91 9880 582728
                </small>
                <small class="text-light">
                  <i class="fa fa-envelope-open me-2"></i>
                  support@elasticcart.com
                </small>
              </div>
            </div>
            {/* <div class="col-lg-4 text-center text-lg-end">
              <div
                class="d-inline-flex align-items-center"
                style={{ height: "45px;" }}
              >
                <a
                  class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href=""
                >
                  <i class="fab fa-twitter fw-normal"></i>
                </a>
                <a
                  class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href=""
                >
                  <i class="fab fa-facebook-f fw-normal"></i>
                </a>
                <a
                  class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href="/ProductsPage"
                >
                  <i class="fab fa-linkedin-in fw-normal"></i>
                </a>
                <a
                  class="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                  href=""
                >
                  <i class="fab fa-instagram fw-normal"></i>
                </a>
                <a
                  class="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                  href=""
                >
                  <i class="fab fa-youtube fw-normal"></i>
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <nav class="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <a href="index.html" class="navbar-brand p-0">
            <h1
              class="m-0"
              style={{
                fontFamily: "",
                letterSpacing: "5px",
                // fontSize: "38px",
              }}
            >
              {/* <i class="fa fa-user-tie me-2"></i> */}
              {/* <img src="/favicon.ico" style={{ width: ".5cm" }}></img> */}
              &nbsp;<b>SANTREZA</b>
            </h1>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span class="fa fa-bars"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
              <a
                href="/Home"
                class={
                  window.location.pathname == "/Home" ||
                  window.location.pathname == "/"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Home
              </a>
              <a
                href="/ProductsPage"
                class={
                  window.location.pathname == "/ProductsPage"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Products
              </a>
              <a
                href="/ServicesPage"
                class={
                  window.location.pathname == "/ServicesPage"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Solutions & Services
              </a>
              <a
                href="/OutsourcingPage"
                class={
                  window.location.pathname == "/OutsourcingPage"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Outsourcing
              </a>
              <a
                href="/CareersPage"
                class={
                  window.location.pathname == "/CareersPage"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Careers & Internships
              </a>
              {/* <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Blog
                </a>
                <div class="dropdown-menu m-0">
                  <a href="blog.html" class="dropdown-item">
                    Blog Grid
                  </a>
                  <a href="detail.html" class="dropdown-item">
                    Blog Detail
                  </a>
                </div>
              </div> */}
              <a
                href="/AboutusPage"
                class={
                  window.location.pathname == "/AboutusPage"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                About
              </a>
              {/* <div class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div class="dropdown-menu m-0">
                  <a href="price.html" class="dropdown-item">
                    Pricing Plan
                  </a>
                  <a href="feature.html" class="dropdown-item">
                    Our features
                  </a>
                  <a href="team.html" class="dropdown-item">
                    Team Members
                  </a>
                  <a href="testimonial.html" class="dropdown-item">
                    Testimonial
                  </a>
                  <a href="quote.html" class="dropdown-item">
                    Free Quote
                  </a>
                </div>
              </div> */}
              <a
                href="/ContactusPage"
                class={
                  window.location.pathname == "/ContactusPage"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Contact
              </a>
            </div>
            {/* <butaton
              type="button"
              class="btn text-primary ms-3"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </butaton> */}
          </div>
        </nav>
      </>
    );
  }
}
