import "./App.css";
import { Container } from "react-bootstrap";
import CartContext from "./Components/Marketing/CartContext";
import React, { createContext, useContext, useEffect, useState } from "react";
import Navigation from "./Components/Marketing/Navigation";
import { Layout } from "./Components/Layout";
import { Home } from "./Components/Marketing/Home";
import { Theme } from "react-select";
import { ProductsPage } from "./Components/Marketing/Products";
import { ServicesPage } from "./Components/Marketing/Services";
import { AboutusPage } from "./Components/Marketing/Aboutus";
import { ContactusPage } from "./Components/Marketing/Contact";
import { QuotePage } from "./Components/Marketing/Quote";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartProvider } from "./Components/Marketing/CartContext";
import { CareersPage } from "./Components/Marketing/careers";
import { OutsourcingPage } from "./Components/Marketing/oursourcing";

function App() {
  const [windowDimension, setWindowDimension] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
    // if (window.innerWidth <= 640) {
    if (window.innerWidth <= 1100) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Container fluid className="m-0 p-0 w-100">
      {isMobile ? (
        <>
          <CartProvider>
            <BrowserRouter>
              <Navigation />
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="ProductsPage" element={<ProductsPage />} />
                  <Route path="ServicesPage" element={<ServicesPage />} />
                  <Route path="CareersPage" element={<CareersPage />} />
                  <Route path="OutsourcingPage" element={<OutsourcingPage />} />
                  <Route path="AboutusPage" element={<AboutusPage />} />
                  <Route path="ContactusPage" element={<ContactusPage />} />
                  <Route path="QuotePage" element={<QuotePage />} />
                  <Route path="*" element={<Home />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </CartProvider>
        </>
      ) : (
        <CartProvider>
          <BrowserRouter>
            <Navigation />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="ProductsPage" element={<ProductsPage />} />
                <Route path="ServicesPage" element={<ServicesPage />} />
                <Route path="OutsourcingPage" element={<OutsourcingPage />} />
                <Route path="CareersPage" element={<CareersPage />} />
                <Route path="AboutusPage" element={<AboutusPage />} />
                <Route path="ContactusPage" element={<ContactusPage />} />
                <Route path="QuotePage" element={<QuotePage />} />
                <Route path="*" element={<Home />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CartProvider>
      )}
    </Container>
  );
}

export default App;
